import { useCountry } from '../country/useCountry'
import { useCMSGlobalSettingsStore } from '~/store'

export function isProductExcludedFromSale(product?: { sku: string; price?: any }) {
  const { isUS, isJapan } = useCountry()
  const { getAttributeByCountry } = useCMSGlobalSettingsStore()
  const excludedProductsUS = getAttributeByCountry('excludedProducts', 'US').value
  const excludedProductsJP = getAttributeByCountry('excludedProducts', 'JP').value

  const productId = product?.sku.slice(0, -1) ?? ''

  return (
    (isUS.value && excludedProductsUS.includes(productId)) ||
    (isJapan.value && excludedProductsJP.includes(productId)) ||
    !product?.price?.value?.centAmount
  )
}
