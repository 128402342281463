








import { defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const { $config } = useContext()
    const renderTarget = $config.cst.isStatic ? 'STATIC' : 'SSR'
    const renderTimestamp = new Date().toISOString()
    const commit = process.env.GITHUB_SHA

    return {
      debugInfo: {
        renderTarget,
        renderTimestamp,
        commit,
      },
    }
  },
})
