import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$e7a6 from '/cstweb-frontend/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as cloudflareRuntime$42c9 from '/cstweb-frontend/node_modules/@nuxt/image/dist/runtime/providers/cloudflare.js'
import * as ipxRuntime$f4f5 from '/cstweb-frontend/node_modules/@nuxt/image/dist/runtime/providers/ipx.js'
import * as mediaserverRuntime$cda8 from '/cstweb-frontend/apps/site/modules/providers/image/media-server.js'
import * as mediacloudflareRuntime$94b1 from '/cstweb-frontend/apps/site/modules/providers/image/media-cloudflare.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$e7a6, defaults: {} },
  ['cloudflare']: { provider: cloudflareRuntime$42c9, defaults: {"baseURL":"https://media.cellsignal.cn/cdn-cgi/imagedelivery/JWox9JU8xLM9DWO4l_Iqsw"} },
  ['ipx']: { provider: ipxRuntime$f4f5, defaults: {} },
  ['ms']: { provider: mediaserverRuntime$cda8, defaults: {} },
  ['mc']: { provider: mediacloudflareRuntime$94b1, defaults: {} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
