



































































































import { defineComponent, onMounted, ref, reactive, watch, nextTick } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/fp/isEmpty'
import IconFaSolidPlusCircle from '~icons/fa-solid/plus-circle'
import { useCheckoutStore } from '@/store/checkout'
import { useProductStore } from '@/store/product'
import { useMobile } from '@/composables/ui/useMobile'
import useEcommerceAnalytics from '@/composables/analytics/useEcommerceAnalytics'
import { useCountry } from '@/composables/country/useCountry'
import { useI18n } from '~/composables/i18n'

import { Errors, isInvalidTypePTMScan, mapExceptionToError } from '@/composables/errors/useErrors'
import { PRODUCT_CATEGORIES, PRODUCT_STATUS, PRODUCT_TYPES } from '@/common/utils/product/constants'
import { isProductExcludedFromSale } from '~/composables/product/isProductExcludedFromSale'
import { AwaitedCustom } from '~/types/common/commonTypes'

export default defineComponent({
  components: { IconFaSolidPlusCircle },
  setup() {
    const { addToBasket } = useCheckoutStore()
    const { getProductStatus } = useProductStore()
    const { trackAddToCart, trackQuickAddClick, trackMultiQuickAddClick } = useEcommerceAnalytics()
    const { isJapan } = useCountry()
    const { t } = useI18n()

    const quickAdd = ref()
    const disabled = ref(true)
    const quickAddValue = ref('')
    const quickAddPopupOpen = ref(false)
    const errors = ref<Errors>([])
    const quickAddMsg = ref({
      msgType: '',
      msg: '',
    })
    const mobile = reactive(useMobile())

    const statusData = ref<AwaitedCustom<ReturnType<typeof getProductStatus>>>()

    const preValidateProduct = (input: string) => {
      const regex = /^\d{4,}[a-zA-Z]{1,}\d{0,}$/
      return !regex.test(input)
    }

    const calcPopupPosition = () => {
      if (quickAddPopupOpen.value === true) {
        let positionCorrection = 80 // desktop size
        if (mobile.isTablet) {
          positionCorrection = 165
        }

        quickAdd.value.lastChild.style.left = quickAdd.value.offsetLeft - positionCorrection + 'px'
      }
    }

    const onClickOutside = () => {
      quickAddPopupOpen.value = false
    }

    const onAddToBasket = async () => {
      if (!disabled.value) {
        try {
          disabled.value = true
          errors.value = []

          const itemArray = [{ id: quickAddValue.value.toUpperCase(), quantity: 1 }]
          await addToBasket(itemArray)

          trackAddToCart('quick_add', itemArray)

          quickAddPopupOpen.value = false
          quickAddValue.value = ''

          clearQuickAddMsg()
        } catch (error) {
          errors.value = mapExceptionToError(error)
        }
      }
    }

    const tryAddToBasket = () => {
      if (statusData.value?.at(0)) {
        if (
          isProductExcludedFromSale(statusData.value[0]) &&
          statusData.value[0].productType !== PRODUCT_TYPES.PTM_SCAN
        ) {
          quickAddMsg.value = {
            msgType: 'error',
            msg: `Product ${quickAddValue.value.toUpperCase()} is currently unavailable`,
          }
          return
        }
        onAddToBasket()
      } else {
        quickAddMsg.value = {
          msgType: 'error',
          msg: `There has been an error while adding to cart.`,
        }
      }
    }

    const clearQuickAddMsg = () => {
      quickAddMsg.value = {
        msgType: '',
        msg: '',
      }
    }

    const onValueChange = async (e: any) => {
      disabled.value = true
      errors.value = []
      clearQuickAddMsg()

      if (quickAddValue.value.length > 3) {
        if (preValidateProduct(quickAddValue.value.toUpperCase())) {
          quickAddMsg.value = {
            msgType: 'warning',
            msg: t('theProductCodeYouEnteredIsNotValidPleaseAddAVvalidAlphanumericProductCodeEG4060S'),
          }
        } else {
          statusData.value = await getProductStatus([quickAddValue.value.toUpperCase()])

          if (isEmpty(statusData.value)) {
            return
          }

          const product = statusData.value[0]
          const isSignalStarProduct = !!product.categories.find(
            (c) =>
              c.name === PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS ||
              c.name === PRODUCT_CATEGORIES.SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS_HTML
          )

          if (isSignalStarProduct) {
            quickAddMsg.value = {
              msgType: 'warning',
              msg: 'ssQuickAddMsg',
            }
          }

          switch (product.status) {
            case PRODUCT_STATUS.RELEASED:
              disabled.value = false
              break
            case PRODUCT_STATUS.DISCONTINUED:
              quickAddMsg.value = {
                msgType: 'error',
                msg: t('productIsDiscontinued', quickAddValue.value.toUpperCase()),
              }
              disabled.value = true
              break
            case PRODUCT_STATUS.RELEASED_ON_HOLD:
              if (isJapan.value) {
                quickAddMsg.value = {
                  msgType: 'error',
                  msg: 'Product ' + quickAddValue.value.toUpperCase() + ' is currently unavailable.',
                }
                disabled.value = true
              } else {
                quickAddMsg.value = {
                  msgType: 'info',
                  msg: 'Product on Backorder - Available Within 30 Days.',
                }
                disabled.value = false
              }
              break
            case PRODUCT_STATUS.PENDING:
            case PRODUCT_STATUS.OBSOLETE:
            case PRODUCT_STATUS.PRE_RELEASED:
            case PRODUCT_STATUS.PRE_DISCONTINUED:
            case PRODUCT_STATUS.COMMERCIALIZATION:
              quickAddMsg.value = {
                msgType: 'error',
                msg: t('productIsCurrentlyUnavailable', quickAddValue.value.toUpperCase()),
              }
              disabled.value = true
              break
            default:
              quickAddMsg.value = {
                msgType: 'warning',
                msg: t('theProductCodeYouEnteredIsNotValidPleaseAddAVvalidAlphanumericProductCodeEG4060S'),
              }
              disabled.value = true
          }
        }
      }
    }

    onMounted(() => {
      window.addEventListener('resize', calcPopupPosition)
    })

    const onClickQuickAdd = () => {
      quickAddPopupOpen.value = true
      trackQuickAddClick()
    }

    const onMultiQuickAddClick = () => {
      trackMultiQuickAddClick()
    }

    watch(quickAddPopupOpen, async () => {
      await nextTick()
      calcPopupPosition()
    })

    return {
      errors,
      disabled,
      quickAdd,
      quickAddMsg,
      quickAddValue,
      quickAddPopupOpen,
      tryAddToBasket,
      onValueChange,
      onClickOutside,
      preValidateProduct,
      onClickQuickAdd,
      onMultiQuickAddClick,
      isInvalidTypePTMScan,
      t,
    }
  },
})
